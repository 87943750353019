@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --category-price-slider-main-color: #{$default-primary-base-color};
}
.CategoryPriceSlider {
    @include desktop {
        padding-bottom: 0;
    }
    &-Button {
        --button-background: var(--category-price-slider-main-color);
        --button-hover-background: var(--category-price-slider-main-color);
        --button-border: var(--category-price-slider-main-color);
        --button-hover-border: var(--category-price-slider-main-color);
    }

    &-Input {
        &:focus {
            background: $white;
            border-color: $default-primary-base-color;
        }
    }
}
