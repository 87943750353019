@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --category-bellow-heading-color: #{$purple80};
}
.CategoryDetailsBellow {
    &-Heading {
        font-family: var(--font-palanquin);
        font-weight: 700;
    }

    &-AdditionalDescription {
        #schemafaq h3 {margin: 0 !important;}
        #schemafaq p {margin:0 !important;padding:10px 0;}
        #schemafaq label {margin:0 !important;}
        #schemafaq {max-width: 100%;width: 100%;margin: 0 auto;clear: both;padding: 0;margin-top:40px;}
        #schemafaq input {position: absolute;opacity: 0;z-index: -1}
        #schemafaq .tabs {border-radius: 0px;overflow: hidden;border: 1px solid #eee;margin-top: 1em;}
        #schemafaq .tab {width: 100%;color: white;overflow: hidden}
        #schemafaq .tab-label {display: -webkit-box;display: flex;-webkit-box-pack: justify;justify-content: space-between;padding: 1em;background: #f9f9f9;font-weight: bold;cursor: pointer;color: #111;transition: all .3s;border-top: 1px solid #eee;font-size:0.6em;}
        #schemafaq .tab:first-child .tab-label {border-top: none}
        #schemafaq .tab-label:hover {background: #eee}
        #schemafaq .tab-label::after {content: "\276F";width: 1em;height: 1em;text-align: center;-webkit-transition: all .3s;transition: all .3s;display: flex;justify-content: center;align-items: center;-webkit-transform: rotateZ(90deg);transform: rotateZ(90deg)}
        #schemafaq .tab-content {max-height: 0;padding: 0 1em;color: #111;background: white;-webkit-transition: all .3s;transition: all .3s}
        #schemafaq .tab-close {display: -webkit-box;display: flex;-webkit-box-pack: end;justify-content: flex-end;padding: 1em;font-size: 0.75em;background: #29abe2;cursor: pointer}
        #schemafaq .tab-close:hover {background: #29abe2}
        #schemafaq input:checked+.tab-label,#schemafaq input:checked+h3>.tab-label {background: #eee;color: #111}
        #schemafaq input:checked+.tab-label::after,#schemafaq input:checked+h3>.tab-label::after {-webkit-transform: rotateZ(180deg);transform: rotateZ(180deg)}
        #schemafaq input:checked~.tab-content {max-height: 100vh;padding: 1em}
    }
}
