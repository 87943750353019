@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --category-filter-overlay-heading-background: #{$default-primary-base-color};
    --category-filter-overlay-button-close-radius: 50px;
    --category-filter-overlay-button-close-background: #{$default-secondary-base-color};
    --category-filter-overlay-expandable-content-icon-mobile-color: #{$default-secondary-base-color};
    --category-filter-overlay-button-submit-background: #{$default-primary-base-color};
    --category-filter-overlay-expandable-content-heading-color: #{$purple80};
    --expandable-content-divider-color: #{$purple10};
}

.CategoryFilterOverlay {
    @include mobileAndTablet {
        --category-filter-overlay-expandable-content-heading-color: #{$default-secondary-base-color};
    }
    &-Button {
        --button-background: var(--category-filter-overlay-button-submit-background);
        --button-hover-background: var(--category-filter-overlay-button-submit-background);
        --button-border: var(--category-filter-overlay-button-submit-background);
        --button-hover-border: var(--category-filter-overlay-button-submit-background);
    }
    .ExpandableContent-Heading {
        @include desktop {
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
        }
    }
    &-Attributes .ExpandableContent {
        @include desktop {
            padding-top: 35px;
            padding-bottom: 35px;
        }
        .ChevronIcon {
            @include desktop {
                display: none;
            }
        }
    }
}
