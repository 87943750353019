@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

:root {
    --category-filter-overlay-heading-font: #{$font-primary};
    --category-filter-overlay-heading-color: #{$white};
    --category-filter-overlay-heading-background: #{$default-secondary-base-color};
    --category-filter-overlay-button-close-color: #{$white};
    --category-filter-overlay-button-close-radius: 10px;
    --category-filter-overlay-button-close-background: #{$default-primary-base-color};
    --category-filter-overlay-expandable-content-heading-color: #{$default-primary-base-color};
    --category-filter-overlay-expandable-content-heading-font: #{$font-primary};
    --category-filter-overlay-expandable-content-icon-desktop-color: #{$grey50};
    --category-filter-overlay-expandable-content-icon-mobile-color: #{$default-primary-base-color};
    --category-filter-overlay-expandable-content-button-border-color: #{$grey-light};
}

.CategoryFilterOverlay {
    @include desktop {
        grid-row: 1/7;
    }

    &.Overlay:not(.Overlay_isStatic) {
        @include mobileAndTablet {
            -webkit-overflow-scrolling: touch;
            position: fixed;
            inset-block-start: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 100%;
            padding-block-end: 0;
            overflow: scroll;
            overflow-x: hidden;
            max-width: 100%;
            transition: transform 200ms ease-in-out;
            transform: translateY(100%);
            will-change: transform;
            z-index: 100;
        }
    }

    &_isVisible {
        &.Overlay:not(.Overlay_isStatic) {
            @include mobileAndTablet {
                transform: translateY(0);
            }
        }
    }

    &-Wrapper {
        @include mobile {
            display: block;
        }
    }

    &-HeadingWrapper {
        background: var(--category-filter-overlay-heading-background);
        display: flex;
        align-items: center;
        padding: 12px 13px;
    }

    &-Heading {
        font-size: 15px;
        line-height: 21px;
        font-weight: 700;
        color: var(--category-filter-overlay-heading-color);
        font-family: var(--category-filter-overlay-heading-font);

        @include mobileAndTablet {
            display: block;
        }
    }

    &-Close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background: var(--category-filter-overlay-button-close-background);
        border-radius: var(--category-filter-overlay-button-close-radius);
        margin-left: auto;

        svg {
            fill: var(--category-filter-overlay-button-close-color);
        }
    }

    &-Icon_filters {
        height: 19px;
        width: 18px;
        margin-right: 18px;
    }

    &-FiltersWrap {
        @include mobileAndTablet {
            padding: 0 17px;
        }
    }

    &-Attributes {
        @include mobileAndTablet {
            padding: 0;
        }
    }

    .ExpandableContent {
        @include mobileAndTablet {
            border-top: 1px solid var(--category-filter-overlay-expandable-content-button-border-color);
        }

        &:last-of-type {
            @include mobileAndTablet {
                border-bottom: none;
            }
        }

        &.CategoryTree {
            @include desktop {
                padding: 0;
            }
        }

        &-Heading {
            font-size: 15px;
            color: var(--category-filter-overlay-expandable-content-heading-color);
            font-family: var(--category-filter-overlay-expandable-content-heading-font);
            cursor: pointer;
        }

        &-Content {
            &_isContentExpanded {
                &.ExpandableContent-Content_isExpandableOnDesktop {
                    @include desktop {
                        margin-top: 27px;
                    }
                }
            }
        }

        &-Button {
            cursor: pointer;

            @include mobileAndTablet {
                padding: 18px 0;
            }
        }

        .ChevronIcon {
            @include desktop {
                fill: var(--category-filter-overlay-expandable-content-icon-desktop-color);
            }

            @include mobileAndTablet {
                fill: var(--category-filter-overlay-expandable-content-icon-mobile-color);
            }
        }
    }

    &-SeeResults {
        @include mobileAndTablet {
            position: static;
            padding: 25px 17px 30px;
        }
    }

    &-ResetSection {
        @include mobileAndTablet {
            display: flex;
            flex-wrap: wrap;
            margin: 0 13px;
        }
    }

    .ResetButton {
        @include tablet {
            margin: 13px 10px 24px 0;
        }

        @include mobile {
            justify-content: center;
            padding: 8px;
            margin: 13px 0 24px;
        }

        &-CloseIcon {
            @include mobile {
                position: static;
            }
        }
    }
}
