@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

:root {
    --category-sort-color: #{$blue50};
    --category-sort-arrow-color: #{$blue50};
    --category-sort-border-color: #{$blue50};
    --category-sort-option-hover-color: #{$blue50};
    --category-sort-radius: 5px;
    --category-sort-width: 163px;
    --category-sort-mobile-width: 154px;
    --category-sort-desktop-width: 193px;
    --category-sort-height: 40px;
}
.CategorySort {
    max-width: var(--category-sort-width);
    @include mobile {
        max-width: var(--category-sort-mobile-width);
    }
    @include desktop {
        margin-inline-start: auto;
        min-width: 180px;
        grid-column: 2;
        max-width: unset;
        display: flex;
        max-width: unset;
    }

    > svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 14px;
        width: 10px;
        path {
            stroke: var(--category-sort-arrow-color);
        }

        @include desktop {
            display: none;
        }
    }
    &-Select {
        margin-block-start: 0;

        @include desktop {
            width: 210px;
            height: 46px;
        }

        label:first-of-type {
            font-size: 16px;
            font-weight: 400;

            @include desktop {
                display: none;
            }

            @include mobileAndTablet {
                padding-block-end: 0;
            }
        }
    }

    &-Placeholder {
        text-align: end;
        opacity: 0;
        animation: appear 100ms 300ms forwards;

        @include mobileAndTablet {
            text-align: start;
        }
    }
    .Field {
        &-LabelContainer {
            width: 100%;
            border-radius: var(--category-sort-radius) var(--category-sort-radius) var(--category-sort-radius)
                var(--category-sort-radius);
            border: 1px solid var(--category-sort-border-color);
            color: var(--category-sort-color);
            padding: 10px 10px 10px 20px;
            display: flex;
            justify-content: space-between;
            min-height: var(--category-sort-height);

            @include mobileAndTablet {
                background: transparent;
            }

            label {
                letter-spacing: 0.5px;
                font-family: $font-primary;
                font-weight: bold;
                font-size: 12px;
                line-height: 150%;
                @include from-tablet {
                    font-size: 13px;
                }
            }
        }
    }
    .Field_type_select .FieldSelect {
        &::after {
            height: 16px;
            width: 16px;

            @include mobileAndTablet {
                inset-inline-start: 42px;
                inset-block-start: -20px;
            }
        }
        &:hover,
        &:focus {
            select {
                color: var(--category-sort-color);
                border-color: var(--category-sort-border-color);
            }
            .ChevronIcon {
                fill: var(--category-sort-color);
            }
        }
        &-Select {
            @include mobileAndTablet {
                position: absolute;
                inset-block-start: -35px;
                opacity: 0;
                top: 0;
                padding: 8.5px;
            }
            @include from-tablet {
                font-size: 13px;
            }
            @include desktop {
                padding: 10px 41px 10px 18px;
                letter-spacing: 0.5px;
                font-family: $font-primary;
                font-weight: bold;
                line-height: 150%;
                border-radius: var(--category-sort-radius) var(--category-sort-radius) var(--category-sort-radius)
                    var(--category-sort-radius);
                border: 1px solid var(--category-sort-border-color);
                color: var(--category-sort-color);
            }
            &_isExpanded {
                border-block-end: 0;
                font-weight: 700;
                @include desktop {
                    border-radius: var(--category-sort-radius) var(--category-sort-radius) 0 0;
                }
            }

            .FieldSelect {
                height: 100%;
            }
        }

        .ChevronIcon {
            @include mobileAndTablet {
                display: none;
            }
        }
        &-Options {
            min-width: unset;
            max-width: var(--category-sort-width);
            top: -5px;
            z-index: -1;
            border-radius: 0 0 var(--category-sort-radius) var(--category-sort-radius);
            &_isExpanded {
                z-index: 9;
                border-color: var(--category-sort-border-color);
            }
            @include mobile {
                max-width: var(--category-sort-mobile-width);
            }
            @include desktop {
                top: 40px;
                max-width: var(--category-sort-desktop-width);
            }

            @include mobileAndTablet {
                top: 15px;
            }
        }
        &-Option {
            &,
            &:first-of-type {
                padding: 0 18px 7px;
                font-size: 12px;
                font-family: $font-primary;
                @include from-tablet {
                    font-size: 13px;
                }
                @include desktop {
                    padding: 0 20px 7px;
                }
                &:hover {
                    color: var(--category-sort-option-hover-color);
                }
            }
            &:first-of-type {
                margin-top: 7px;
            }
            &:last-of-type {
                margin-bottom: 7px;
            }
        }
        &-Clickable {
            background: transparent;
            position: absolute;
            z-index: 9;
            top: -40px;
            height: var(--category-sort-height);
            max-width: var(--category-sort-width);
            @include mobile {
                max-width: var(--category-sort-mobile-width);
            }
            @include desktop {
                position: static;
                max-width: var(--category-sort-desktop-width);
            }

            @include mobileAndTablet {
                top: -20px;
            }

            .ChevronIcon {
                right: 40px;
                fill: var(--category-sort-arrow-color);
                path {
                    stroke: var(--category-sort-arrow-color);
                }
            }
        }
    }
    .Field.Field_type_select.CategorySort-Select .Field-LabelContainer {
        @include mobileAndTablet {
            background: transparent;
            top: -20px;
            left: 0;
            padding: 0 18px;
        }
        @include desktop {
            display: none;
        }
        .ChevronIcon {
            @include desktop {
                display: none;
            }
        }
        .Field-Label {
            @include mobileAndTablet {
                letter-spacing: 0.5px;
                font-weight: bold;
                font-size: 12px;
                line-height: 150%;
                color: var(--category-sort-color);
            }
        }
    }
    &-AdditionalLabel {
        font-family: $font-primary;
        font-weight: normal;
        font-size: 13px;
        letter-spacing: -0.4px;
        color: $grey-dark;
        display: none;
        height: var(--category-sort-height);
        line-height: var(--category-sort-height);
        margin-right: 16px;

        @include desktop {
            display: block;
        }
    }
}
