@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

:root {
    --pagination-accent-color: #{$default-secondary-base-color};
    --pagination-active-link-border-radius: 5px;
}

.CategoryPagination .ChevronIcon {
    --color-black: var(--pagination-accent-color);
}

.CategoryPaginationLink {
    padding: 9px 15px;
    min-width: 40px;
    min-height: 40px;
    border-radius: var(--pagination-active-link-border-radius);
    border: 0;
    font-size: 15px;
    line-height: 150%;
    font-family: $font-secondary;
    color: $grey-dark;
    font-weight: 400;
    margin-inline-end: 10px;
    cursor: pointer;

    @include mobile {
        min-width: 30px;
        min-height: 30px;
        padding: 3px 5px;
        margin-inline-end: 0;
    }

    &_isArrow {
        margin-right: 0;
        display: flex;
        align-items: center;

        @include mobile {
            margin-inline-end: 0;
        }
    }

    &_isCurrent {
        background: var(--pagination-accent-color);
        color: $white;

        &:hover,
        &:focus {
            color: $white;
        }
    }
}
