@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --category-sort-color: #{$purple50};
    --category-sort-arrow-color: #{$purple50};
    --category-sort-border-color: #{$purple50};
    --category-sort-option-hover-color: #{$purple50};
    --category-sort-border-radius: 100px;
    --category-sort-border-radius-hover: 25px;
    --category-sort-additional-label-color: #{$purple80};
}

.CategorySort {
    .Field.Field_type_select.CategorySort-Select .Field-LabelContainer,
    .Field_type_select .FieldSelect-Select {
        border-radius: var(--category-sort-border-radius);
    }
    .Field.Field_type_select.CategorySort-Select .Field-LabelContainer_isExpanded,
    .Field_type_select .FieldSelect-Select_isExpanded {
        border-radius: var(--category-sort-border-radius-hover) var(--category-sort-border-radius-hover) 0 0;
    }
    .Field_type_select .FieldSelect_isExpanded + .Field-LabelContainer {
        border-radius: var(--category-sort-border-radius-hover) var(--category-sort-border-radius-hover) 0 0 !important;
    }
    .Field_type_select .FieldSelect-Options {
        border-radius: 0 0 var(--category-sort-border-radius-hover) var(--category-sort-border-radius-hover);
    }

    &-AdditionalLabel {
        color: var(--category-sort-additional-label-color);
    }
}
