@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --category-tree-heading-color: #{$purple80};
    --category-tree-heading-font: #{$font-primary};
    --category-tree-category-color: #{$purple80};
    --category-tree-category-accent-color: #{$default-secondary-base-color};
    --category-tree-back-color: #{$purple30};
    --category-tree-border-color: #{$purple20};
}

.CategoryTree {
    @include mobileAndTablet {
        --category-tree-category-color: #{$default-secondary-base-color};
    }
    &-ExpandableContentButton {
        @include desktop {
            margin-bottom: 12px;
        }
    }
    &-ExpandableContentContent {
        @include desktop {
            border: 1px solid var(--category-tree-border-color);
            padding: 20px 27px;
            border-radius: 20px;
        }
    }
    &-Categories {
        border: 0;
        margin: 0;
        padding: 0 7px;
        &_hasParentCategory {
            @include desktop {
                margin-top: 20px;
                padding: 20px 7px 0;
                border-top: 1px solid var(--category-tree-border-color);
                border-radius: 0;
            }
        }
    }
    &-Category {
        &:before {
            width: 12px;
            height: 24px;
            left: -40px;
            transform: translateY(-50%) rotate(90deg);
        }
    }
    &-BackButton {
        @include desktop {
            margin-bottom: 10px;
            padding-bottom: 0;
        }
    }
    &-Button {
        @include desktop {
            font-size: 15px;
        }
    }
}
