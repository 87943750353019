@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --category-items-count-color: #{$purple80};
}

.CategoryPage {
    &-ItemsCount {
        opacity: 0.5;
    }
    &-Filter {
        border-radius: 100px;
    }
    &-CMS {
        border-radius: 50px;
    }
}
